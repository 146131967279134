var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "content" }, [
    _c("div", { staticClass: "top-img-box" }, [
      _c("img", {
        staticClass: "top-img",
        attrs: { src: _vm.topPicture.pictureAddress, alt: "" }
      })
    ]),
    _c("div", { staticClass: "title" }, [
      _vm.linguisticType == 0
        ? _c("span", { staticClass: "span-title" }, [_vm._v("团队")])
        : _vm._e(),
      _vm.linguisticType == 1
        ? _c("span", { staticClass: "span-title" }, [_vm._v("Team")])
        : _vm._e()
    ]),
    _c("div", { staticClass: "team" }, [
      _c(
        "div",
        { staticClass: "team-list" },
        _vm._l(_vm.team_list, function(v, i) {
          return _c("div", { key: i, staticClass: "animated fadeInUp" }, [
            _c("h3", { staticClass: "h3" }, [
              _c("span", [_vm._v(_vm._s(v.userName))]),
              _c("span", { staticClass: "job-title" }, [
                _vm._v(_vm._s(v.userType))
              ])
            ]),
            _c("div", { staticClass: "longline" }),
            _c("p", [_vm._v(_vm._s(v.note))])
          ])
        }),
        0
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }