//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { scrollInit } from '../utils/scroll';
import { mapState } from 'vuex';
export default {
  name: "team",

  data() {
    return {
      topPicture: {},
      team_list: [],
      linguisticType: localStorage.getItem('linguisticType')
    };
  },

  computed: { ...mapState({
      device: state => state.device
    })
  },
  watch: {
    $route(to, from) {
      // this.scrollInit()
      scrollInit(this.$route.query.hash);
    },

    device() {
      let that = this;
      setTimeout(function () {
        this.getMemberImg();
      }, 0);
    }

  },

  created() {
    this.getImg();
    this.getList();
  },

  mounted() {
    if (this.$route.query.hash) scrollInit(this.$route.query.hash);
  },

  methods: {
    getImg() {
      this.$http.get(this.$api.listTopPicture, {
        params: {
          pictureType: 3
        }
      }).then(res => {
        if (res.code == 0) {
          if (res.data.length > 0) {
            this.topPicture = res.data[0];
          }
        }
      });
    },

    getList() {
      this.$http.get(this.$api.investorInfoList, {
        params: {
          linguisticType: this.linguisticType
        }
      }).then(res => {
        console.log(res);
        this.team_list = res.data;
      });
    }

  }
};